import React from "react"
import Layout, { Main } from "../wrappers/layout"
import Logo from "../components/logo"
import { Meta } from "../components/meta"
import artwork from "../images/holding-page-artwork-v1.jpeg"
import NewsletterForm from "../components/newsletter"

export default function HoldingPage () {
  return (
    <Layout>
			<Main className="holding-page">
				<div className="holding-logo"><Logo /></div>
				<div className="prel holding-artwork">
					<div className="fs pabs">
						<img src={artwork} alt="Bard — A new home for craft and design in Scotland." />
					</div>
				</div>
				<div className="holding-info">
					<div className="span-4 delay-1">
						<p className="what-copy">
							Bard is a new shop and gallery <br /> for Scottish craft and design, opening soon in Leith, Edinburgh.
						</p>
						
					</div>
					<div className="span-4 delay-2">
						<p>
							Founded by husbands Hugo Macdonald and James Stevens, Bard is a home for discovering people, objects and ideas that embody Scottish cultural identity - past, present and future.
						</p>
						<p className="has-links">
							Follow us on <a href="//instagram.com/bard.scotland">Instagram</a> and <a href="//facebook.com/bard.scotland">Facebook</a>.
						</p>
					</div>
					<div className="span-4 newsletter-wrapper delay-3">
						<NewsletterForm />
					</div>
				</div>
			</Main>
    </Layout>
  )
}

export const Head = () => {
	const title = "Bard — Opening soon"
	const description = "Bard is a new shop and gallery for Scottish craft and design, opening soon in Leith, Edinburgh."
	const imageSrc = "/bard-opening-soon.jpeg"

	return <Meta title={title} description={description} imageSrc={imageSrc} />
}
